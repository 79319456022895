<template>
    <div id="arena-features" class="top page">  
    <Gallery :images="[
      {file:'enmarket-arena/top/SavannahArena-HeroGallery-Image1.jpg', title: ''},
      {file:'enmarket-arena/top/SavannahArena-HeroGallery-Image2.jpg', title: ''},
      {file:'enmarket-arena/top/SavannahArena-HeroGallery-Image3.jpg', title: ''},
      {file:'enmarket-arena/top/SavannahArena-HeroGallery-Image4.jpg', title: ''},
      {file:'enmarket-arena/top/SavannahArena-HeroGallery-Image5.jpg', title: ''},
      {file:'enmarket-arena/top/SavannahArena-HeroGallery-Image6.jpg', title: ''},
    ]"/>
    <div class='limit-width'>
      <transition name="slide-fade" mode="out-in" appear>
        <div>
          <h1 class="white">Inspired by the History of the Area and Offering the Best Views of Historic Downtown</h1>
          <h4 class="white">Enmarket Arena features exposed steel tying back to the industrial aspects of the city.</h4>      
        </div> 
      </transition>
    </div>
  </div>
</template>

<script>
import Gallery from '@/components/Gallery';
export default {
    name:'OurVision',
    components: {      
      Gallery,
  },
}
</script>