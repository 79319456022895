<template>
  <div>
    <SubNav :items="pages" :currentPage="currentPage"/>
    <Component v-for="page in activePages" :key="page.id" :is="page.component" />        
    <Footer />
  </div>
</template>

<script>
import { shallowRef } from 'vue'
import axios from 'axios';

import Footer from '@/components/Footer';
import SubNav from '@/components/SubNav';

import ArenaFeatures from '@/pages/ArenaFeatures';
import Locations from '@/pages/Locations';
import GettingHere from '@/pages/GettingHere';
import AnchorTenants from '@/pages/AnchorTenants';
import YearRoundEntertainment from '@/pages/YearRoundEntertainment';

const arenaFeatures = shallowRef(ArenaFeatures)
const locations = shallowRef(Locations)
const gettingHere = shallowRef(GettingHere)
const anchorTenants = shallowRef(AnchorTenants)
const yearRoundEntertainment = shallowRef(YearRoundEntertainment)
export default {
  name: 'EnmarketArena',
  components: {
      Footer,
      SubNav,      
  },
  mounted() {
      if(this.$store.state.preview) {
        return;
      }
      const post = {
        method:"page",
        purl:this.$store.state.purl,
        sessionDbId:this.$store.state.sessionDbId,
        section:'3'
      }
      axios.post(this.$store.state.baseUrl + "EpitchLiteTracking.php", post);     
  },
  computed: {
    activePages() {
        let serverPages = this.$store.state.pages.filter(serverPage => {      
            return this.pages.findIndex(page => serverPage.id === page.id) === -1 ? false : true;                
        });
        let pages = [];
        serverPages.forEach(element => {
          pages.push({...element, ...this.pages[this.pages.findIndex(page => element.id === page.id)]});
          
        });
        return pages;
    }
  },
  data: () => ({
      currentPage:'arena-features',         
      pages : [
          {
            id:"17",
            path : 'enmarket-arena',
            hash : 'arena-features',
            name : 'Arena Features',
            component:arenaFeatures
          },
          {
            id:"18",
            path : 'enmarket-arena',
            hash : 'locations',
            name : 'Locations',
            component:locations
          },
          {
            id:"19",
            path : 'enmarket-arena',
            hash : 'getting-here',
            name : 'Getting Here',
            component:gettingHere
          },
          {
            id:"20",
            path : 'enmarket-arena',
            hash : 'anchor-tenants',
            name : 'Anchor Tenants',
            component:anchorTenants
          },
          {
            id:"21",
            path : 'enmarket-arena',
            hash : 'year-round-entertainment',
            name : 'Year Round Entertainment',
            component:yearRoundEntertainment
          },
      ] 
  }),
}
</script>

<style lang="scss">
.tabs {  
  width:100%;
  background: rgba(32, 32, 32, 0.75);
  z-index: 1;
  text-align: center;
  position: relative;
  &.tabs-top {
    position:absolute;
    top:0;
    left:0;        
  }
  > div {        
    display: inline-grid;
    margin:0 auto;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    > button {
      display: inline-block;
      vertical-align: top;
      flex-grow:1;
      height:58px;
      background:none;
      border:none;
      text-transform: uppercase;
      color:#fff;
      cursor: pointer;
      user-select: none;
      padding:1.25rem;
      transition: all 0.25s cubic-bezier(1, 0.5, 0.8, 1);
      &.active {
        pointer-events: none;
      }
      &:hover, &.active {
        color:#fff;
        background-color: #E43141;
      }
    }
  }
}
#commitment, #artists, #livenation, #greatest-secret, #rising-demand, #year-round-entertainment {
  .cards {  
    flex-direction: column;    
    margin-left:15px;  
    flex-shrink: 0;
    margin-bottom: 45px;
    > div {
      height: 200px;
      width:385px;
      &:not(:last-child) {
        margin-bottom:15px;
      }
    }
  }
  .artist-cards, .secret-cards, .rising-demand-cards, .year-round-entertainment-cards {
    max-width: 1300px;    
    .cards {
      flex-direction: row;
      > div {
        flex-shrink: 1;
        flex-grow: 1;
        margin:0 auto;
        &:not(:last-child) {                    
          margin-bottom:15px;
        }
      }
    }
  }
  .year-round-entertainment-cards {
    padding:35px 0;
    max-width: unset;    
  }
  .commitment-cards {    
    margin-top: 30px;    
    .cards {
      margin-bottom: 15px;    
      margin-left:0;
    }
    .card {
      margin:0 auto;
    }
    .fill {
      width:385px;
      //height:630px;
      margin-bottom: 15px;
    }
  }
}
@media (min-width:750px) {
  #commitment, #artists, #livenation, #greatest-secret, #rising-demand, #year-round-entertainment {
    .artist-cards, .secret-cards, .rising-demand-cards, .year-round-entertainment-cards {
      .cards {
        display:flex;
        margin-right: 15px;
        > div {
          &:not(:last-child) {                              
            margin-right:15px;
            margin-bottom:0;
          }
        }
      }      
    }
    .commitment-cards {
      display: flex;
      > .card {
        margin-left:15px;
        height: 630px;
        margin-bottom: 0;
      }
    }
  }
}
</style>
<style lang="scss" scoped>


h1 {
    max-width: 650px;
    margin-top:22px;
    margin-bottom:12px;
  }  
h4 {
  max-width: 550px;
}
.top {
  .cta {
    margin-top: 20px;
  }
}
  
@media (max-width:570px) {
  .tabs.tabs-top > div {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 58px);    
  }
}
@media (min-width:945px) {
  .tabs > div > button {
    padding:1.25rem 3.125rem;
  }
  .tabs.tabs-top > div {    
    grid-auto-columns: 1fr;
  }
}  
</style>