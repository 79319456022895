<template>
    <div id="getting-here" class="dark-gradient" :style="`min-height:calc(100vh - 140px); background-size:cover; background-image:url('${require('@/assets/images/enmarket-arena/getting-here/getting-here.jpg')}')`">    
    <div  class="getting-here-address" :style="`min-height:calc(100vh - 140px); background-size:cover; background-image:url('${require('@/assets/images/enmarket-arena/getting-here/getting-here.svg')}')`"></div>
    <div class='add-padding'>
      <div class='limit-width'>
        <PageTitle title="Getting Here"/>
        <h4 class='white'>Drive times from key locations:</h4>        
        <DrivingLocation 
          v-for="(location, id) in drivingLocations"           
          @click.native="currentDrivingLocation = id"     
          :class="[currentDrivingLocation == id ? 'open' : '']" 
          :key="location.name" 
          :name="location.name" 
          :duration="location.duration"/>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle';
import DrivingLocation from '@/components/DrivingLocation';
export default {
    name:'OurVision',
    components: {      
      PageTitle,
      DrivingLocation
  },
  data: () => ({
      currentDrivingLocation:-1,
      drivingLocations: [
        {
          name:'Savannah Civic Center',
          duration:'5 Mins'
        },
        {
          name:'Hilton Head Airport',
          duration:'20 Mins'
        },
        {
          name:'Bluffton, SC',
          duration:'30 Mins'
        },
        {
          name:'Palmetto Bluff, SC',
          duration:'35 Mins'
        },
        {
          name:'Hilton Head, SC',
          duration:'45 Mins'
        },
        {
          name:'Hinesville, GA',
          duration:'50 Mins'
        },
        {
          name:'Beaufort, SC',
          duration:'60 Mins'
        },
        {
          name:'Statesboro, GA',
          duration:'60 Mins'
        },
        {
          name:'St. Simons Island',
          duration:'90 Mins'
        },
      ],      
  }),
}
</script>

<style lang="scss" scoped>
  #getting-here {
    overflow: hidden;
    .getting-here-address {
      position: absolute;
      top:0;
      left:0;
      object-fit: cover;
      width:100%;
      height:100%;
      min-height: calc(100vh - 140px);
    }
    h4 {
      margin-bottom:15px;
    }
  }
</style>