<template>
    <div>
    <div id="anchor-tenants" class="dark-gradient" :style="`background-size:cover; background-image:url('${require('@/assets/images/enmarket-arena/music/livenation.jpg')}')`">    
    <div class="limit-width">
      <div class='page'>
        <transition name="slide-fade" mode="out-in" appear>
          <div>
            <h1 class="white">The First of its Kind for Live<br/>Entertainment in</h1>
            <em class="accent">Music <span class="white">&</span> Sports</em>
            <h4 class="white">Savannah will finally have a venue that can host large scale tours and events, with outstanding, unobstructed views for up to 10,000 fans</h4>
          </div>
        </transition>
      </div>    
    </div>    
  </div>  
  <div>
    <div class="tabs" style="position:sticky; top:140px; z-index:2;">
        <div  style="display:grid; max-width:1300px;">
          <button class='body'  :class="[id === currentAnchorTenant ? 'active' : '']" v-for="(tenant, id) in tenants" :key="tenant.name" @click="currentAnchorTenant = id" >{{tenant.name}}</button>
      </div>
    </div>  
    <div v-if="currentAnchorTenant == 0" :style="`background-size:cover; background-image:url('${require('@/assets/images/enmarket-arena/music/background.jpg')}')`">
      <div id="livenation">
        <div class="centered-headline">
          <transition name="slide-fade" mode="out-in" appear>
            <div>
              <h3 class="white">Strategic Partnership With</h3>
              <h1 class="white">Live Nation</h1>
              <h4 class="white">The powerhouse partner in the music industry capable of delivering the most exciting entertainment</h4>
            </div>
          </transition>
          <transition name="slide-fade" mode="out-in" appear>
          <div class='artist-cards'>
              <div class='cards'>
                <Card whiteSubtitle :card="{h1:'30K+', title:'World’s Largest Live Event Promoter with', subtitle:'Events in 40 Countries Annually'}"/>    
                <Card whiteSubtitle :card="{h1:'Artists', title:'Own the Trust of the World’s Biggest', subtitle:'& Their Management'}"/>    
                <Card whiteSubtitle :card="{h1:'18 Min', title:'There is a Livenation concert every', subtitle:'& THeir Management'}"/>              
              </div>
            </div>
          </transition>
        </div>
      </div>
      <div id="commitment">
        <img src="@/assets/images/enmarket-arena/music/commitment.jpg" alt="" class='fullwidth'>
        <div class="centered-headline">
          <transition name="slide-fade" mode="out-in" appear>
            <div>
              <h3 class="white">Savannah’s New</h3>
              <h1 class="white">Commitment<br/>to Music and Entertainment</h1>
              <h4 class="white">Georgia’s focus on bringing the entertainment industry to the state provides the perfect opportunity for a new, state-of-the-art venue</h4>
            </div>
          </transition>
          <transition name="slide-fade" mode="out-in" appear>
            <div class='commitment-cards'>
              <div class='cards'>
                <Card :card="{icon:'enmarket-arena/music/growing.svg', title:'Georgia Entertainment Investment Act', description:'In 2008, georgia enacted this policy in an effort to create more jobs in the state. It has successfully created more than 92,000 jobs, paying close to $4.6 billion in wages.'}"/>    
                <Card :card="{icon:'enmarket-arena/music/scissors.svg', title:'Tax Deductions', description:'Provides a 20 percent tax credit for companies that spend $500K+ or more on production and post-production in Georgia, either in a single production or on multiple projects'}"/>    
                <Card :card="{icon:'enmarket-arena/music/venue.svg', title:'Developing New Live Entertainment Venues', description:'Provides a 20 percent tax credit for companies that spend $500K+ or more on production and post-production in Georgia, either in a single production or on multiple projects'}"/>              
              </div>
              <Card class='fill' :card="{icon:'enmarket-arena/music/hotspot.svg', title:'The Next Hot Spot For Music'}"/>    
            </div>
          </transition>
        </div>
      </div>
      <div id="artists">
        <img src="@/assets/images/enmarket-arena/music/artists.jpg" alt="" class='fullwidth'>
        <div class="centered-headline">
          <transition name="slide-fade" mode="out-in" appear>
            <div>
              <h1 class="white">An attractive Venue for Artists</h1>
              <h4 class="white">Enmarket Arena will become the most attractive place for artists to rehearse and launch their tour</h4>
            </div>
          </transition>
          <transition name="slide-fade" mode="out-in" appear>
            <div class='artist-cards'>
              <div class='cards'>
                <Card :card="{icon:'enmarket-arena/music/airplane.svg', title:'Easy Access To', subtitle:'Gulfstream', description:'While rehearsing, Artists can have their private jets serviced nearby at Gulfstream to  ensure they’re prepared for the tour'}"/>    
                <Card :card="{icon:'enmarket-arena/music/world.svg', title:'Starting point for', subtitle:'Major Tours', description:'Artists who leverage the rehearsal space will open their national tours at Enmarket Arena'}"/>    
                <Card :card="{icon:'enmarket-arena/music/programming.svg', title:'Influence', subtitle:'Programming', description:'The OVG Network connects venues with similar capacities (`10`,000-12,000), offering  an easy solution for bookers to route both world-wide tours and intimate shows'}"/>              
              </div>
            </div>
          </transition>
          <img src="@/assets/images/enmarket-arena/music/end.jpg" alt="" class='fullwidth'>
        </div>
      </div>
    </div>
    <div v-if="currentAnchorTenant == 1" :style="`background-size:cover; background-image:url('${require('@/assets/images/enmarket-arena/sports/background.jpg')}')`">
      <div id="greatest-secret">
        <div class="centered-headline">
          <transition name="slide-fade" mode="out-in" appear>
            <div>
              <h3 class="white">The Southeast coast’s</h3>
              <h1 class="white">Greatest Secret<br/>Hockey Town</h1>
              <h4 class="white">Savannah Civic Center played host to the popular hockey tournament featuring the four most exciting programs in the south for 20 straight years</h4>
            </div>
          </transition>
          <transition name="slide-fade" mode="out-in" appear>
            <div class='secret-cards'>
              <div class='cards'>
                <Card :card="{icon:'enmarket-arena/sports/uga-icedogs.png', description:'UGA Ice Dogs'}"/>    
                <Card :card="{icon:'enmarket-arena/sports/ferris-state-bulldogs.png', description:'Florida State Seminoles'}"/>    
                <Card :card="{icon:'enmarket-arena/sports/georgia-tech-athletics.png', description:'Georgia Tech Athletics'}"/>    
                <Card :card="{icon:'enmarket-arena/sports/florida-gators.png', description:'Florida Gators'}"/>    
              </div>
            </div>
          </transition>
        </div>
      </div>
      <div id="rising-demand">
        <img src="@/assets/images/enmarket-arena/sports/rising-demand.jpg" alt="" class='fullwidth'>
        <div class="centered-headline">
          <transition name="slide-fade" mode="out-in" appear>
            <div>
              <h3 class="white">Meeting the</h3>
              <h1 class="white">rising demand for hockey<br/>in the south</h1>
              <h4 class="white">Enmarket Arena will become the home of a new ECHL franchise beginning in 2022</h4>
            </div>
          </transition>
          <transition name="slide-fade" mode="out-in" appear>
            <div class='rising-demand-cards'>
              <div class='cards'>
                <Card :card="{icon:'enmarket-arena/sports/seat-capacity.svg', title:'7300 Seat Capacity', description:'Enough Seats to House all the Fans'}"/>    
                <Card :card="{icon:'enmarket-arena/sports/team-office.svg', title:'Team Office Spaces', description:'Over 800 sq. ft'}"/>    
                <Card :card="{icon:'enmarket-arena/sports/team-operations.svg', title:'Team Operations Space', description:'Coach’s Office, Equipment Room & Medical Spaces'}"/>    
                <Card :card="{icon:'enmarket-arena/sports/locker-rooms.svg', title:'3 Locker Rooms ', description:'Coach’s Office, Equipment Room & Medical Spaces'}"/>    
                <Card :card="{icon:'enmarket-arena/sports/premium-space.svg', title:'Premium Space & Club Seating', description:'Lorem Upsum'}"/>    
              </div>
            </div>
          </transition>
          <img src="@/assets/images/enmarket-arena/sports/end.jpg" alt="" class='fullwidth'>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle';
import Card from '@/components/Card';
export default {
    name:'OurVision',
    components: {      
      PageTitle,
      Card
    },
    data: () => ({
      currentAnchorTenant:0,
      tenants: [
        {
          name:'Music'
        },
        {
          name:'Sports'
        }
      ],            
  }),
}
</script>

<style lang="scss" scoped>
  .fullwidth {
    width:100%;
  }
  #anchor-tenants {
    .page {
      position: relative;
      padding:150px 0;
      overflow: auto;
      box-sizing: border-box;
    }
  }
</style>