<template>
  <div class="card body" :class="[card.description ? '' : 'no-animation', hasHover ? 'has-hover' : '']">
        <div class="inner">
        <div class="front white" :style="card.image ? 'background-image:url(' + require(`@/assets/images/${card.image}`) + ')' : ''">            
            <div>
                <inline-svg :src="require(`@/assets/images/${card.icon}`)"  v-if="card.icon && hasHover"/>
                <img :src="require(`@/assets/images/${card.icon}`)" alt="" v-else-if="card.icon"/>
                <div>
                    <div v-if="card.title">{{card.title}}</div>
                    <h2 class='accent' v-if="card.h1">{{card.h1}}</h2>
                    <div :class="[whiteSubtitle ? 'white': 'accent']" v-if="card.subtitle">{{card.subtitle}}</div>
                </div>
            </div>
        </div>
        <div class="back"><div>{{card.description}}</div></div>
        </div>
    </div>
</template>

<script>
export default {
    name:'Card',
    props : {
        card:Object,
        whiteSubtitle:Boolean,
        hasHover:Boolean
    },    
}
</script>

<style lang="scss">
.card {
     &.has-hover {
        .front:after {
            transition: background 0.8s;
        }
        .front svg path {
            transition: fill 0.8s;
        }
        &:hover {
            .front {
                svg path{
                    fill:#fff;
                }
                &:after {
                    background:#E43141;
                }
            }
        }
    }
}
</style>
<style lang="scss" scoped>
.card {  
    background-color: transparent;
    width: 205px;
    height: 245px;  
    perspective: 1000px;
    user-select: none;
    &:not(.no-animation) {
        cursor: pointer;
    }    
   
    .inner {
        position: relative;
        width: 100%;
        height: 100%;
        transition: transform 0.8s;
        transform-style: preserve-3d;        
    }
    .inner > div {
        position: absolute;
        width: 100%;
        height: 100%;
        
    }
    &:not(.no-animation):hover .inner {
        transform: rotateY(180deg);
    }
    .front {
        background-size: cover;
        display:flex;
        justify-content: center;
        align-items: center;        
        padding:15px;
        text-align: center;
        box-sizing: border-box;        
        &:after {
            background: rgba(32, 32, 32, 0.75);
            width:inherit;
            height:inherit;
            position: absolute;
            top:0;
            left:0;
            content:"";
        }
        img {
            position: relative;
            z-index: 1;
        }
        div {
            position: relative;
            z-index: 1;
        }
    }
    .front {
        text-transform: uppercase;
        backface-visibility: hidden;
    }
    .back {
        backface-visibility: hidden;
        background: #FFFFFF;
        transform: rotateY(180deg);
        display:flex;
        justify-content: center;
        align-items: center;
        color:#202020;
        padding:15px;
        text-align: center;
        box-sizing: border-box;
        z-index: 1;
    }
}
</style>