<template>
  <div class='driving-location'>
      <button>
        <div class='details'>
            <div>
                <h3 class='accent white'>{{name}}</h3>
            </div>            
        </div>
        <div class="duration body"><div>{{duration}}</div></div>
        <div class='arrow'><Arrow /></div>
      </button>
  </div>
</template>

<script>
import Arrow from '@/svg/arrow';
export default {
    name:'DrivingLocation',
    props : {
        duration:String,
        name:String
    },
    components : {
        Arrow
    },  
}
</script>

<style lang="scss" scoped>    
    .details {
        padding:10px;
        
        max-width: 285px;
        display: inline-block;        
        align-items: center;
        height: inherit;
        box-sizing: border-box;
        width: 285px;
        h4 {
            max-width: 200px;
        }
    }
    .arrow {
        padding:0 15px;
        align-items: center;
        justify-content: center;
        align-self: center;
    }
    .driving-location {  
        pointer-events: none;      
        > button {
            pointer-events: all;      
            outline: none;
            text-align: left;
            background-color:rgba(32, 32, 32, 0.75);
            transition: background-color 0.25s cubic-bezier(1, 0.5, 0.8, 1), height 0.25s cubic-bezier(1, 0.5, 0.8, 1);
            position: relative;
            padding:0;         
            display: flex;   
            .accent {
                transition: color 0.25s cubic-bezier(1, 0.5, 0.8, 1);
            }
            svg {
                transition: all 0.25s cubic-bezier(1, 0.5, 0.8, 1);
            } 
                     
            &:hover, &.open {
                background-color:#E43141;
                .accent {
                    color:#ffffff;
                }
                svg {
                    stroke:#202020;
                }
            }
        }   
        &.open {
            button {
                background-color:#E43141;
                .accent {
                    color:#ffffff;
                }
                svg {
                    stroke:#202020;
                }
            }
            svg {
                transform: scaleX(-1);
            }
            .duration {
                max-width: 100px;
            }
        }              
    }
    .duration {
        max-width:0;
        background:#fff;
        transition: max-width 0.25s cubic-bezier(1, 0.5, 0.8, 1);         
        overflow: auto;  
        height:38px;
        white-space: nowrap;
        display: flex;        
        box-sizing: border-box;
        align-items: center;
        overflow: hidden;
        text-align: center;
        > div {
            padding:10px;
            min-width: 60px;
        }
    }
    button {        
        background:none;
        border:none;
        cursor: pointer;
         svg {
            stroke:#E43141;
        }
    }    
</style>