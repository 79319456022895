<template>
    <div id="year-round-entertainment" class="light-gradient" :style="`background-size:cover; background-image:url('${require('@/assets/images/enmarket-arena/year-round-entertainment/year-round-entertainment.jpg')}')`">    
    <div class='add-padding'>
      <div class='limit-width'>
        <PageTitle title="Year Round Entertainment" subtitle="In total, the new Enmarket Arena will deliver" dark/>
        <div>
          <h4>Of live entertainment & experiences</h4>  
          <div class='year-round-entertainment-cards'>   
            <div class='cards'>
              <Card whiteSubtitle :card="{icon:'enmarket-arena/year-round-entertainment/concerts.svg', h1:'25+', subtitle:'Concerts' }"/>    
              <Card whiteSubtitle :card="{icon:'enmarket-arena/year-round-entertainment/year-round-events.svg', h1:'75+', subtitle:'Family, Theatre & Other Live Events' }"/>    
              <Card whiteSubtitle :card="{icon:'enmarket-arena/year-round-entertainment/sporting-events.svg', h1:'55+', subtitle:'Sporting Events' }"/>    
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '@/components/PageTitle';
import Card from '@/components/Card';
export default {
    name:'OurVision',
    components: {      
      PageTitle,
      Card
  },
}
</script>