<template>
<div id="locations" class="dark-gradient">
    <Gallery :images="locations[currentLocation].images" showTitles/>    
    <div class="tabs tabs-top" v-if="locations.length > 0">
        <div>
          <button class='body' :class="[id === currentLocation ? 'active' : '']" v-for="(location, id) in locations" :key="location.name" @click="currentLocation = id" >{{location.name}}</button>
      </div>
    </div>
    <div class="centered-headline">
      <transition name="slide-fade" mode="out-in" appear>
        <div>
          <h1 class="white">{{locations[currentLocation].h1}}</h1>
          <h4 class="white">{{locations[currentLocation].h4}}</h4>
        </div>
      </transition>
    </div>
  </div>    
</template>

<script>
import Gallery from '@/components/Gallery';
export default {
    name:'OurVision',
    components: {      
      Gallery,
    },
    data: () => ({
      currentLocation:0,      
      locations: [
        {
          name:'Lobby',
          h1:'A First-Class Experience from the First Step Inside',
          h4:'The main entrance lobby features a vaulted ceiling and hanging lights with authentic wood paneling.',
          images:[
            {file:'enmarket-arena/lobby/ground-floor.jpg', title: 'Lobby View - Ground Floor'}, 
            {file:'enmarket-arena/lobby/concourse-level.jpg', title: 'Lobby View - Concourse Level'}
          ]
        },
        {
          name:'Seating',
          h1:'Where the Nosebleed Section Doesn’t Exist',
          h4:'Outstanding, unobstructed views for up to 9,300 fans on two levels of seating perfect for variety of events coming to Savannah Arena.',
          images:[
            {file:'enmarket-arena/seating/basketball.jpg', title: 'Lower Bowl - Basketball'}, 
            {file:'enmarket-arena/seating/hockey.jpg', title: 'Lower Bowl - Hockey'}, 
            {file:'enmarket-arena/seating/event.jpg', title: 'Lower Bowl - Event'}, 
            {file:'enmarket-arena/seating/overlook-area-1.jpg', title: 'Lower Bowl - Overlook Area'}, 
            {file:'enmarket-arena/seating/overlook-area-2.jpg', title: 'Lower Bowl - Overlook Area'}, 
          ]
        },
         {
          name:'Hospitality',
          h1:'Upscale Hospitality',
          h4:'Designed to be congruent with Savannah’s historic charm.',
          images:[
            {file:'enmarket-arena/hospitality/north-club.jpg', title: 'North Club'}, 
            {file:'enmarket-arena/hospitality/east-club-1.jpg', title: 'East Club'}, 
            {file:'enmarket-arena/hospitality/east-club-dining-area.jpg', title: 'East Club Dining Area'}, 
            {file:'enmarket-arena/hospitality/east-club-2.jpg', title: 'East Club'}, 
            {file:'enmarket-arena/hospitality/suite-interior-1.jpg', title: 'Suite Interior'}, 
            {file:'enmarket-arena/hospitality/suite-interior-2.jpg', title: 'Suite Interior'}, 
            {file:'enmarket-arena/hospitality/suite-interior-3.jpg', title: 'Suite Interior'}, 
          ]
        },
        {
          name:'Food',
          h1:'An Authentic Culinary Experience',
          h4:'Collaborating with local chefs to curate concession offerings.',
          images:[
            {file:'enmarket-arena/food/food-court.jpg', title: 'Food Court'}, 
          ]
        },
        {
          name:'Bars',
          h1:'Strategically Located Bars',
          h4:'Bars are located strategically to allow fans to continually enjoy the action or grab fresh air.',
          images:[
            {file:'enmarket-arena/bars/concourse-bar-with-lower-blowl-views.jpg', title: 'Concourse Bar with Lower Bowl Views'},
            {file:'enmarket-arena/bars/overlook-bar-terrace-access.jpg', title: 'Overlook Bar with Terrace Access'},
          ]
        },
        {
          name:'Terrace',
          h1:'Enjoy Sweeping Views of Savannah from a Unique Vantage Point',
          h4:'Terraces are located on both the concourse and overlook levels.',
          images:[
            {file:'enmarket-arena/terrace/concourse-terrace.jpg', title: 'Concourse Terrace'}, 
            {file:'enmarket-arena/terrace/boat-entrance-terrace.jpg', title: 'Boat Entrance - Terrace'}, 
            {file:'enmarket-arena/terrace/overlook-terrace.jpg', title: 'Overlook Terrace'}, 
          ]
        },
      ],      
  }),
}
</script>